import React, { useEffect, useState, useContext } from 'react';
import DomainContext from "Context/DomainContext";
import "./styles.scss";
import ModelNew from 'Components/Model/ModelNew';
import CloseButton from 'Components/CloseButton';
import Input from 'Components/Common/Form/Input';
import Button from 'Components/Common/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ValidSuccesArrow, ValidErrorArrow } from "Res/icons/index";
import Rating from "@mui/material/Rating";
import useForm from "Components/Hooks/useForm";
import { APIQueryPost } from "APIMethods/API";
import { useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";

const ReviewForm = ({ openModel, setOpenModel, product_id, productTitle }) => {

    const [resMessage, setResMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("review") === "1") {
            setOpenModel(true);
        }
      }, [searchParams, setOpenModel]);
    
      const handleReviewClick = () => {
        setOpenModel(false);
      const currentParams = new URLSearchParams(window.location.search);
    
        currentParams.delete("review");
    
        setSearchParams(currentParams, { replace: true });
    };
    

    return (
        <ModelNew
            from="right"
            hideScroll={false}
            zindex={11}
            open={openModel}
            shadow={true}
            setOpen={setOpenModel}
            className="header__review__sidebar"
        >
            <div className="sidebar__review w-1/1 h-1/1 py-4">
                <div className="close__block tr flex right w-1/1 px-4 sm-px-6 ">
                    <CloseButton onClickFunction={handleReviewClick} />
                </div>
                <div className="review__wrapper__container px-4 sm-px-6 w-1/1 h-1/1 overflow-hidden overflow-y-auto">
                    <div className="sidebar__heading pb-10">
                        <h1 className="fw-700 pb-1 fs-20">Schrijf een review voor</h1>
                        <h1 className="fw-700 pb-4 fs-20">{productTitle}</h1>
                        <p className="line-6 fs-15">Wij controleren elk ingezonden product review. Na goedkeuring verschijnt uw review op de productpagina.</p>
                    </div>
                    <div className="review__form">
                        <Form
                            product_id={product_id}
                            resMessage={resMessage}
                            setResMessage={setResMessage}
                            openModel={openModel}
                        />
                    </div>
                </div>
            </div>
        </ModelNew>
    )
}

export default ReviewForm;

const Form = ({ product_id, setResMessage, resMessage, openModel }) => {
    const customerDetails = useSelector((state) => state?.customerDetails);
    const { baseURL, storeId } = useContext(DomainContext);
    const [value, setValue] = useState(0);
    const [isProcessign, setIsProcessing] = useState(false);
    const [images, setImages] = useState([]);
    const inputRef = React.useRef(null);

    const {
        data,
        setData,
        errors,
        setErrors,
        changeHandler,
        submitHandler,
        onBlur,
        success,
        setSuccess,
    } = useForm({
        validations: {
            review: {
                required: {
                    value: true,
                    message: "dit veld is verplicht.",
                }
            },
            summary: {
                required: {
                    value: true,
                    message: "dit veld is verplicht.",
                },
                pattern: {
                    value: /^[\s\S]{0,250}$/,  
                    message: "Maximaal 250 tekens toegestaan.",
                },
            
            },
            nickname: {
                required: {
                    value: true,
                    message: "dit veld is verplicht.",
                }
            },
            rating: {
                required: {
                    value: true,
                    message: "dit veld is verplicht.",
                }
            }
        },
        initialValues: {
            rating: "",
            nickname: "",
            summary: "",
            review: ""
        },
        onSubmit: () => reviewSubmitHandler(),
    });

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    
    const reviewSubmitHandler = async () => {
        try {
            // Convert all image files to base64 strings
            const imagePromises = images.map(async img => {
                const base64String = await fileToBase64(img.file);
                return {
                    name: img.file.name,
                    type: img.file.type,
                    // size: img.file.size,
                    imageUrl: base64String
                };
            });
            
            // Wait for all base64 conversions to complete
            const imageData = await Promise.all(imagePromises);
            
            const loginOptions = {
                isLoader: true,
                loaderAction: (bool) => setIsProcessing(bool),
                setGetResponseData: (resData) => {
                    if (resData?.data?.[0]?.code === 200) {
                        setResMessage(resData?.data?.[0]?.message);
                        clearValues();
                    }
                },
                getStatus: (res) => {
                    if (res?.status !== 200) {
                        setResMessage(res?.message);
                    }
                },
                axiosData: {
                    url: `${baseURL}/review/create`,
                    paramsData: {
                        data: {
                            productId: product_id,
                            customerId: customerDetails?.id ? customerDetails?.id : null,
                            customerName: data?.nickname,
                            reviewTitle: data?.summary,
                            reviewDetail: data?.review,
                            ratingValue: value || 0,
                            storeId: storeId,
                            images: imageData.length > 0 ? imageData : [] 
                        }
                    }
                },
            };
            APIQueryPost(loginOptions);
        } catch (error) {
            console.error("Error processing images:", error);
            setResMessage("Er is een fout opgetreden bij het uploaden van afbeeldingen.");
        }
    };
    const clearValues = () => {
        setData({
            rating: "",
            nickname: "",
            summary: "",
            review: ""
        });
        setSuccess({});
        setErrors({});
        setValue(0);
        setImages([]); // Clear images when form is reset
    };
    useEffect(() => {
        if (openModel) {
            clearValues()
        }
    }, [openModel])
    
    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const newImages = files.map((file) => {
          return {
            id: URL.createObjectURL(file),
            file: file,
          };
        });

        setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const removeImage = (id) => {
        setImages(images.filter((img) => img.id !== id));
    };
      
    return <form
        className="flex col gap-2 pb-9"
        onSubmit={submitHandler}
        noValidate
    >
        <div className="rating relative pb-3">
            <p className="label fs-16 fw-700 pb-1">Totaalscore artikel *</p>
            <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                    if (newValue !== null) {
                        let rateValue = newValue.toString();
                        setValue(rateValue);
                        setData({ ...data, rating: rateValue });
                        setErrors({});
                    }

                }}
            />
            <Input
                name="rating"
                value={data?.rating}
                lable="Bijnaam"
                controlClassName="hide"
            />
            <span className={`field__error error absolute bottom-0 left-0 fs-12 w-1/1 ${errors?.rating ? 'v-show' : 'v-hide'}`}>{errors?.rating ? errors?.rating : 'Hello'}</span>
        </div>

        <Input
            iconClass="top-11"
            name="nickname"
            value={data?.nickname}
            lable="Uw naam *"
            labelClassName="fs-15 fw-700 line-6"
            type="text"
            onChange={changeHandler}
            onBlur={() => onBlur("nickname")}
            icon={
                success?.nickname === "true" ? (
                    <ValidSuccesArrow />
                ) : success?.nickname === "false" ? (
                    <ValidErrorArrow />
                ) : null
            }
            showIcon={true}
            errorMessage={errors?.nickname === data?.nickname ? "" : errors?.nickname}
        />
        <Input
            iconClass="top-11"
            name="summary"
            value={data?.summary}
            lable="Productervaring in één zin *"
            labelClassName="fs-15 fw-700 line-6"
            type="text"
            onChange={changeHandler}
            errorMessage={
                errors?.summary === data?.summary ? "" : errors?.summary
            }
            onBlur={() => onBlur("summary")}
            icon={
                success?.summary === "true" ? (
                    <ValidSuccesArrow />
                ) : success?.summary === "false" ? (
                    <ValidErrorArrow />
                ) : null
            }
            showIcon={true}
        />
        <Input
            iconClass="top-20"
            element="textarea"
            name="review"
            value={data?.review}
            lable="Wat vindt u van het product? *"
            labelClassName="fs-15 fw-700 line-6"
            type="text"
            onChange={changeHandler}
            errorMessage={
                errors?.review === data?.review ? "" : errors?.review
            }
            onBlur={() => onBlur("review")}
            icon={
                success?.review === "true" ? (
                    <ValidSuccesArrow />
                ) : success?.review === "false" ? (
                    <ValidErrorArrow />
                ) : null
            }
            showIcon={true}
        />
        <div>
          
        <label htmlFor="" className='label fs-15 fw-700 line-6'>Foto uploaden</label>
      <div className="upload-container">
      {images.map((img) => (
        <div key={img.id} className="image-wrapper">
          <img src={img.id} alt="Preview" className="image-preview" />
          <button onClick={() => removeImage(img.id)} className="close-btn">
            ✕
          </button>
        </div>
      ))}
      <label htmlFor="fileUpload" className="upload-box">
        +
        <input
          ref={inputRef}  
          type="file"
          id="fileUpload"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />
      </label>
    </div> 
    </div>
        {resMessage && (
            <div className={`res__message pt-5 ${resMessage.includes("succesvol") ? "success" : "error"}`}>{resMessage}</div>
        )}
        <div className="action__block">
            <Button className={`r-6 px-2 py-3 pointer fw-700 ${isProcessign ? "rotateUpdate" : ""}`}>
                {isProcessign ? <AutorenewIcon /> : "Versturen"}
            </Button>
        </div>

    </form>
}
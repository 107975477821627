import React, { memo, useContext, useEffect , useState } from 'react';
import DomainContext from 'Context/DomainContext';
import { Helmet } from 'react-helmet-async';

const Seo = ({
  metaTitle = "",
  metaDescription = "",
  metaKeywords = "",
  ogImage = "",
  ogImageWidth = "265",
  ogImageHeight = "265",
  ogWebSite = "Website",
  productPrice = "",
  currency = "€",
  isValid = false,
  robots = "INDEX,FOLLOW",
}) => {
  const { storeId } = useContext(DomainContext);
  const locExpTit = process.env.REACT_APP_EXPOFIT_TITLE;
  const locExpDes = process.env.REACT_APP_EXPOFIT_DESCRIPTION;
  const locProTit = process.env.REACT_APP_PROMOFIT_TITLE;
  const locProDes = process.env.REACT_APP_PROMOFIT_DESCRIPTION;
  const [robotsContent, setRobotsContent] = useState("");
  const defaultKeywords = storeId === 1 ? "default promofit keywords" : "default expofit keywords";

  useEffect(() => {
    const dec = document.getElementById('meta-description') || createMetaTag('meta-description', 'description');
    dec.setAttribute("content", metaDescription || (storeId === 1 ? locProDes : locExpDes));
    
    const titleTag = document.querySelector('title');
    titleTag.textContent = metaTitle || (storeId === 1 ? locProTit : locExpTit);
    // Meta title
    const title = document.getElementById('meta-title') || createMetaTag('meta-title','name','title');
    title.setAttribute("content", metaTitle || (storeId === 1 ? locProTit : locExpTit));

    const keywords = document.getElementById('meta-keywords') || createMetaTag('meta-keywords','name' , 'keywords');
    keywords.setAttribute("content", metaKeywords || defaultKeywords);

    const ogUrl = document.getElementById('og-url') || createMetaTag('og-url', 'property', 'og:url');
    ogUrl.setAttribute("content", window.location.origin + window.location.pathname);

    const ogType = document.getElementById('og-type') || createMetaTag('og-type', 'property', 'og:type');
    ogType.setAttribute("content", ogWebSite);

    const ogTitle = document.getElementById('og-title') || createMetaTag('og-title', 'property', 'og:title');
    ogTitle.setAttribute("content", metaTitle || (storeId === 1 ? locProTit : locExpTit));

    const ogDescription = document.getElementById('og-description') || createMetaTag('og-description', 'property', 'og:description');
    ogDescription.setAttribute("content", metaDescription || (storeId === 1 ? locProDes : locExpDes));

    const ogImageTag = document.getElementById('og-image') || createMetaTag('og-image', 'property', 'og:image');
    ogImageTag.setAttribute("content", ogImage);

    const ogImageWidthTag = document.getElementById('og-image-width') || createMetaTag('og-image-width', 'property', 'og:image:width');
    ogImageWidthTag.setAttribute("content", ogImageWidth);

    const ogImageHeightTag = document.getElementById('og-image-height') || createMetaTag('og-image-height', 'property', 'og:image:height');
    ogImageHeightTag.setAttribute("content", ogImageHeight);

    const canonical = document.getElementById("meta-canonical") || createLinkTag("meta-canonical", "canonical");
    canonical.setAttribute("href", window.location.origin + window.location.pathname);

//commeneted for purpose
//    const robotsTag = document.getElementById('meta-robots') || createMetaTag('meta-robots', 'name', 'robots');
//   // const hostName = window?.location?.hostname;
//   const hostName = "promofit.nl";
//   const decode = (t) => atob(t);
//   const robotsContent = hostName.includes(decode('cHJvbW9maXQubmw=')) ||  
//   hostName.includes(decode('ZXhwb2ZpdC5ubA=='))  
// ? robots  
// : "NOINDEX,NOFOLLOW";
//   robotsTag.setAttribute("content", robotsContent);
//     const canonical = document.getElementById('meta-canonical') || createLinkTag('meta-canonical', 'canonical');
//     canonical.setAttribute("href", window.location.origin + window.location.pathname);

  }, [
    metaTitle, 
    metaDescription, 
    metaKeywords, 
    ogImage, 
    ogImageWidth, 
    ogImageHeight, 
    ogWebSite, 
    robots, 
    storeId
  ]);

  useEffect(() => {
    const hostName = window?.location?.hostname; 
    const decode = (t) => atob(t);
    const newRobotsContent =
      hostName.includes(decode("cHJvbW9maXQubmw=")) ||
      hostName.includes(decode("ZXhwb2ZpdC5ubA=="))
        ? robots
        : "NOINDEX,NOFOLLOW";

    setRobotsContent(newRobotsContent); 

  }, [robots, storeId]);
  useEffect(() => {
    const robotsTag =
      document.getElementById("meta-robots") ||
      createMetaTag("meta-robots", "name", "robots");
    robotsTag.setAttribute("content", robotsContent);
  }, [robotsContent]);

  const createMetaTag = (id, nameOrProperty, name = nameOrProperty) => {
    const meta = document.createElement('meta');
    meta.setAttribute(nameOrProperty, name);
    meta.id = id;
    document.head.appendChild(meta);
    return meta;
  };

  const createLinkTag = (id, rel) => {
    const link = document.createElement('link');
    link.setAttribute('rel', rel);
    link.id = id;
    document.head.appendChild(link);
    return link;
  };

  return (
    <Helmet>
        {/* SEO tags */}
        {isValid && <meta name="renderly-status-code" content="404"></meta>}
        {/* Open Graph tags */}
        
        {/* Product details */}
        <meta property="product:price:currency" content={currency} data-react-helmet="true" />
        <meta property="product:price:amount" content={productPrice} data-react-helmet="true" />
    </Helmet>
)
};

export default memo(Seo);
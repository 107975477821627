import image from "Res/images/placeholder-image.webp";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";

const SkeletonImg = ({
  width = "100%",  
  height = "100%", 
  animation,
  color = "#e5e7eb",
  className = "",
  style = {},
}) => {
  const placeholderImage = useSelector(state => state?.getHomePageData?.data?.place_holder_image);

  useEffect(() => {
    if (placeholderImage) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = placeholderImage;
      document.head.appendChild(link);
    }
  }, [placeholderImage]);

  return (
    <div
      className={`relative r-4 flex center middle ${className} ${
        animation === false
          ? ""
          : animation === "pulse"
          ? "animate-pulse"
          : "animate-wave"
      }`}
      style={{ width, height, background: color, ...style }}
    >
      <img
        src={placeholderImage}
        alt=""
        style={{ objectFit: "cover", width, height }}
      />
    </div>
  );
};

export default SkeletonImg;
